import React, { useState } from 'react';
import { Button, Card, CardContent, Typography } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import LinkIcon from '@mui/icons-material/Link';

const ShareComponent = ({ url }) => {
  const [copiado, setCopiado] = useState(false);

  const copiarAlPortapapeles = () => {
    navigator.clipboard.writeText(url)      
    setCopiado(true);
    setTimeout(() => setCopiado(false), 2000);  // Resetea el estado después de 2 segundos
  };

  return (
      <>
        <Typography variant="h5" style={{ textAlign: 'center', marginBottom: '1rem' }}>
          Compartir resultados
        </Typography>
        
        <Button 
          variant="outlined" 
          color="success"
          style={{ margin: '0.5rem' }}
          startIcon={<WhatsAppIcon />}
          href={`https://wa.me/?text=${encodeURIComponent(url)}`} 
          target="_blank" 
          rel="noopener noreferrer">
          WhatsApp
        </Button>

        <Button 
          variant="outlined" 
          color="primary"
          style={{ margin: '0.5rem' }}
          startIcon={<TelegramIcon />}
          href={`https://t.me/share/url?url=${encodeURIComponent(url)}`} 
          target="_blank" 
          rel="noopener noreferrer">
          Telegram
        </Button>

        <Button 
          variant="contained" 
          color={copiado ? "success" : "primary"}
          style={{ margin: '0.5rem' }}
          startIcon={copiado ? <LinkIcon /> : null}
          onClick={copiarAlPortapapeles}>
          {copiado ? '¡Copiado!' : 'Copiar enlace'}
        </Button>
    </>
  );
};

export default ShareComponent;