import React, { useState } from 'react';
import '../components/css/teclado.css';

const Teclado = ({ onKeyPress }) => {
  const teclas = [
    ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
    ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'Ñ'],
    ['ENVIAR', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', '  ⌫  ']
  ];

  const [pressedKey, setPressedKey] = useState(null);

  const handleMouseDown = (tecla) => {
    setPressedKey(tecla);
    onKeyPress(tecla);
  };

  const handleMouseUp = () => {
    setPressedKey(null);
  };

  return (
    <div className="theme-teclado">
      {teclas.map((fila, index) => (
        <div key={index} className="theme-teclado-fila">
          {fila.map((tecla) => (
            <button
              className={`theme-teclado-button ${pressedKey === tecla ? 'active' : ''} ${('ENVIAR' !== tecla && '  ⌫  ' !== tecla) ? 'hg-standardBtn' : 'hg-controlBtn'}`}
              translate="no"
              key={tecla}
              onTouchStart={() => handleMouseDown(tecla)}              
              onTouchEnd={handleMouseUp}
            >
              {tecla}
            </button>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Teclado;