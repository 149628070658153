import React from 'react';
import './css/letterBox.css';
import { Zoom } from '@mui/material';

const LetterBox = ({ text }) => {
  // Limitar el texto a 25 caracteres
  const truncatedText = text.slice(0, 25);

  // Convertir el texto en un array de letras
  const letters = truncatedText.split('');

  return (
    <div className="letter-box-container">
      {letters.map((letter, index) => (
        <Zoom in={true}><div className="letter-box" key={index}>
          {letter}
        </div></Zoom>
      ))}
    </div>
  );
};

export default LetterBox;