import React, { useState, useEffect } from 'react';
import './css/scoreIcon.css';
import { Typography } from '@mui/material';

const ScoreIcon = ({ scoreProp }) => {
    const [score, setScore] = useState(scoreProp);
    const [isBroken, setIsBroken] = useState(false);
  
    useEffect(() => {
      // Observar cambios en los puntos y aplicar el efecto de romperse
      if (scoreProp !== score) {
        setIsBroken(true);
        setTimeout(() => {
          setIsBroken(false);
        }, 500);
        setScore(scoreProp);
      }
    }, [scoreProp, score]);
  
    return (
      <div className="score-icon-container">
        <div className={`score ${isBroken ? 'broken-icon' : ''}`}>
            {score}
        </div>
        <div className={`icon ${isBroken ? 'broken-icon' : ''}`}>
          🌟
        </div>
      </div>
    );
  };
  
  export default ScoreIcon;