import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';

const Proximamente = () => {
    const [tiempoFaltante, setTiempoFaltante] = useState(null);

  useEffect(() => {
    const intervalo = setInterval(() => {
      const ahora = new Date();
      const horasHastaMañana = new Date();
      horasHastaMañana.setDate(ahora.getDate() + 1);  // Obtener la fecha de mañana
      horasHastaMañana.setHours(0, 0, 0, 0);  // Establecer la hora a las 00:00:00

      const diferenciaMilisegundos = horasHastaMañana - ahora;

      const horas = Math.floor(diferenciaMilisegundos / (1000 * 60 * 60));
      const minutos = Math.floor((diferenciaMilisegundos % (1000 * 60 * 60)) / (1000 * 60));
      const segundos = Math.floor((diferenciaMilisegundos % (1000 * 60)) / 1000);

      const tiempoFormateado = `${horas.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}:${segundos.toString().padStart(2, '0')}`;

      setTiempoFaltante(tiempoFormateado);
    }, 1000);  // Actualizar cada segundo

    return () => {
      clearInterval(intervalo);  // Limpiar el intervalo al desmontar el componente
    };
  }, []);

  return (
    <div>
      {tiempoFaltante !== null ? (
        <Typography variant="h5" style={{ textAlign: 'center', marginBottom: '1rem' }}>
          Próximo juego: {tiempoFaltante}
        </Typography>
      ) : (
        <Typography variant="h5" style={{ textAlign: 'center', marginBottom: '1rem' }}>
          Próximo juego: ...
        </Typography>
      )}
    </div>
  );
};
export default Proximamente;