import '../App.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Typography,  Button, Alert, Box, Grid, Container } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom'; 

const Share = () => {
    const {datos } = useParams();    
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null)
    const [data, setData] = useState(null);
    const [dataShare, setDataShare] = useState([]);
    const [dataFinal, setDataFinal] = useState([]);

    useEffect(() => {
        if (datos) {
            setLoading(true);
            try {
                const decodedData = JSON.parse(atob(datos));    
                fetch("https://juego-backend.educoteca.es/?fecha=" + decodedData[0])
                    .then((response) => response.json())
                    .then((data) => setData(data))
                    .catch((error) => {
                        console.error("Error en la llamada", error);
                        setError(error);
                    })
                    .finally(() => setLoading(false));

                    setDataShare(decodedData.slice(1))
    
            } catch (error) {
                console.error('No es válida la información que has compartido :( ', error);
                setLoading(false);
            }
        }
    }, [datos]);
    
    useEffect(() => {
        const newDataFinal = [];
        if (data && dataShare) {
            data.forEach(item => {    
                const p1 = item.palabra1;
                const p2 = item.palabra2;    
                const matchedShareItem = dataShare.find(shareItem => getParamFromDataShare(shareItem, 0) === item.codigo.toString());
    
                if (matchedShareItem) {
                    const p = getParamFromDataShare(matchedShareItem, 1);
                    const t = getParamFromDataShare(matchedShareItem, 2);
                    const a = true;
                    newDataFinal.push({ p1, p2, a, p, t });
                } else {
                    const p = "0";
                    const t = "0";
                    const a = false;
                    newDataFinal.push({ p1, p2, a, p, t });
                }
            });
            setDataFinal(newDataFinal);
        }
    }, [data, dataShare]);

    const getParamFromDataShare = (dataShareItem, posicion) => {
        return dataShareItem.split('|')[posicion]; 
    };

    return (
        <div>
            <Box
                className="Share"
                sx={{
                    display: 'flex',
                    width: '100%',
                    bgcolor: 'background.default',
                    color: 'text.primary',
                }}
            >
                {loading && <Box sx={{ display: 'flex' }}> <CircularProgress /> </Box>}

                <Container>
                    {dataFinal && Array.isArray(dataFinal) ? (
                        <>
                            {dataFinal.map((item, index) => (
                                <Accordion expanded={false}>
                                    <AccordionSummary sx={{borderRadius: 2, backgroundColor: item.a ? 'rgb(237, 247, 237)' : 'rgb(253, 237, 237)'}}>
                                        <Alert sx={{ width: '100%' }} severity={(item.a ? "success" : "error")}><Typography component="div" >  {index + 1}. Relación entre <b>{item.p1}</b> y <b>{item.p2}</b> <br></br>{(item.a ? "Puntos: "+ item.p + " acertó en " + (60 - item.t) + " seg " : "")} </Typography></Alert>
                                    </AccordionSummary>
                                </Accordion>
                            ))}
                        </>
                    ) : (
                        <p>No se ha proporcionado un parámetro válido.</p>
                    )}
                    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" sx={{p:2}}>
                        <Link to="/"><Button variant="contained" size="large">Jugar</Button></Link>
                    </Grid>
                </Container>
            </Box>
        </div>
    );
};

export default Share;