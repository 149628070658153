import React, { useState, useEffect } from 'react';
import '../App.css';

const Timer = ({ isRunning, handleToggleTimer, tiempo}) => {
  const [seconds, setSeconds] = useState(60);
  const [circleClass, setCircleClass] = useState('circle-progress');

  const radius = 21;
  const circumference = (2 * Math.PI * radius) +1;

  useEffect(() => {
    if (isRunning && seconds > 0) {
      const intervalId = setInterval(() => {
        setSeconds((prevSeconds) => {       
            tiempo(seconds);     
          if (prevSeconds === 0) {
            clearInterval(intervalId);
            setCircleClass('circle-progress-red');
            return prevSeconds;
          }
          return prevSeconds - 1;
        });
      }, 1000);

      return () => clearInterval(intervalId);
    }else if(seconds === 0){
        tiempo(0);
        setCircleClass('circle-progress-red');
        handleToggleTimer(false);
    }
  }, [isRunning, seconds]);

  const calculateProgress = () => {
    const progress = (seconds / 60) * 100;
    return circumference - (progress / 100) * circumference;
  };

  return (
    <div className="timer-container">
      <svg height="46" width="46" viewBox="0 0 46 46">
        <circle className="circle-background" r={radius} cx="23" cy="23" stroke="#ccc" />
        <circle
          className={circleClass}
          r={radius}
          cx="23"
          cy="23"
          strokeDasharray={circumference}
          strokeDashoffset={calculateProgress()}
        />
        <text x="50%" y="50%" className="timer-text">
          {seconds}s
        </text>
      </svg>
    </div>
  );
};

export default Timer;