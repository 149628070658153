import React from 'react';
import { Modal, Box, Typography, Grid, Button, FormControlLabel, Switch } from '@mui/material';


const Introduccion = ({abrirExplicaciones, setAbrirExplicaciones}) => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 2,
      };

    const handleClose = () => setAbrirExplicaciones(false)

    
    return (
        <>
    <Modal
        open={abrirExplicaciones}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            ¡Instrucciones!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Son 5 rondas de relaciones y para cada una dispones de <b>30 segundos</b>.  <br></br><br></br>
            En cada ronda tendrás dos palabras y debes encontrar una tercera que esté relacionada con las dos primeras, empezando por la letra que te indica. <br></br><br></br>
            Por ejemplo, entre <b>"corriente" y "conductor"</b>, la palabra relacionada sería "<u>electricidad</u>" porque comparte relación con ambas palabras.<br></br><br></br>
            Piensa en cómo las palabras pueden estar conectadas y diviértete haciendo esas conexiones.  <br></br><br></br>
            Al final tendrás un resumen de tus aciertos y fallos.
          </Typography>
          <Grid container justifyContent="center" alignItems="center" item xs={12}>
            <Button variant="contained" onClick={handleClose}>¡Empezar!</Button>            
          </Grid>

        </Box>
      </Modal>
        </>
      );
}

export default Introduccion;