import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Share from './components/Share';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
/*import TuComponente from './TuComponente'; // Importa tu componente*/

const root = ReactDOM.createRoot(document.getElementById('root'));
const theme = createTheme({
  
})

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/share/:datos" element={<Share/>} />
        </Routes>
      </Router>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
