import '../App.css';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button, TextField, Grid, Typography, Paper , Alert, Zoom, Fade, Container, Box} from '@mui/material';
import Timer from './Timer';
import ScoreIcon from './ScoreIcon';
import Teclado from './Teclado';
import LetterBox from './LetterBox';

const Pregunta = ({ ask, isActive, siguiente, maxIntentos, index, finalizar, handleResumen }) => {

  const [check, setCheck] = useState(false);
  const [acierto, setAcierto] = useState(false);
  const [respuesta, setRespuesta] = useState('');
  const [intento, setIntento] = useState(1);
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const [tiempo, setTiempo] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [pista, setPista] = useState(ask.relacion.substr(0, intento));
  const [puntos, setPuntos] = useState(20);

  useEffect(() => {
    if ((!isTimerRunning && puntos !== 0 && !acierto)) {
      setPuntos(prevPuntos => { return 0; });
      agregarResultado("", ask, false, 0);
    }
  }, [puntos, isTimerRunning]);


  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };

  const handleRespuesta = (strRespuesta) => {
    document.querySelector("#respuesta").value = strRespuesta;
    setRespuesta(strRespuesta);
  };

  const agregarResultado = (respuesta, ask, acertado, puntos) => {
    const nuevoElemento = {
      respuesta: respuesta,
      ask: ask,
      tiempo: tiempo,
      acertado: acertado,
      puntos: puntos,
    };

    handleResumen(nuevoElemento)
  };

  const toInputUppercase = e => {
    setRespuesta(e.target.value.toUpperCase());
  };  

  const comprobar = (respuesta) => {
    if(isTimerRunning){
      if (intento === maxIntentos) {
        setCheck(true);
        setIsTimerRunning(false)
        setPuntos(0)
        agregarResultado(respuesta, ask, false, 0)
      } else if (removeAccents(respuesta.toUpperCase()) === removeAccents(capitalizar(ask.relacion))) {
        setCheck(true);
        setAcierto(true);
        setIsTimerRunning(false)
        agregarResultado(respuesta, ask, true, puntos)
  
      } else {
        const intentoActual = intento + 1;
        if(intentoActual === maxIntentos) {
          setCheck(true);
          setIsTimerRunning(false)
          setPuntos(0)
          agregarResultado(respuesta, ask, false, 0)
        }else{
          setIntento(intentoActual);
          handleRespuesta(capitalizar(pista));
          setPista(ask.relacion.substr(0, intentoActual ));
          setRespuesta(capitalizar(ask.relacion.substr(0, intentoActual)));
          setPuntos(puntos-5)
        }
      }
    }
  }

  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  } 

  const capitalizar = (str) => {
    return str.toUpperCase();
  }

  function onKeyPress(button) {        
    if (button === "ENVIAR" && document.querySelector("#respuesta").value !== '') {
      comprobar(document.querySelector("#respuesta").value);
    } else if (button !== "  ⌫  " && button !== "ENVIAR") {
      handleRespuesta((respuesta + button).slice(0, 25))
    } else if (button === "  ⌫  ") {
      if(document.querySelector("#respuesta").value.length !== pista.length){
        handleRespuesta(respuesta.substr(0, document.querySelector("#respuesta").value.length - 1));
      }      
    }
  }

  return (
    <>
      <form onSubmit={ev => {
        ev.preventDefault();
        ev.currentTarget.blur();
        comprobar(respuesta);
      }}>

        <Paper
          elevation={10}
          variant="elevation"
          sx={{
            p: 2,
            margin: 'auto',
            maxWidth: 700,
            flexGrow: 1,
            paddingTop: 2,
          }}
        >
          <Grid container direction="row" spacing={2}>

            {/*<Grid container alignItems="center" item xs={1}><Box sx={{ display: 'flex', width: 38, height: 38 }}><img src={question} alt="Your SVG" /></Box> </Grid> */}

            <Grid item xs={12} sm={6}>
              <Zoom in={true}><Typography component="div" variant="h5" gutterBottom>{capitalizar(ask.palabra1)}</Typography></Zoom>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Zoom in={true} style={{ transitionDelay: '250ms' }}><Typography component="div" variant="h5" gutterBottom>{capitalizar(ask.palabra2)}</Typography></Zoom>
            </Grid>

            <Grid container justifyContent="center" alignItems="center" item xs={12}>
            <Alert sx={{width:'100%'}} severity={(isTimerRunning && !check?"info":(check && acierto?"success":"error"))}>
              {isTimerRunning && !check && <Typography component="div" variant="body1"> La relación empieza por <b>{capitalizar(pista)}</b></Typography>}
              {check && <Typography component="div" color="body1">{acierto && <>¡Correcto!</>}{!acierto && <>¡Fallaste!</>} La palabra es: <b>{ask.relacion}</b> </Typography>}
              {!isTimerRunning && !check && <Typography component="div" color="body1">¡Se acabó el tiempo! La palabra es: <b>{ask.relacion}</b></Typography>}
              </Alert>
            </Grid>

            <Grid item xs={12} sm={6}>
            {!isMobile && <TextField fullWidth id="respuesta" name="respuesta" variant="outlined"                
                label={pista}
                autoFocus
                onInput={toInputUppercase}                
                value={respuesta} />}
                {isMobile && <><input type='hidden' id="respuesta" name="respuesta" value={respuesta?respuesta:setRespuesta(pista)} />
                <LetterBox text={respuesta?respuesta:setRespuesta(pista)} /></>}
                
            </Grid>

            <Grid container justifyContent="center" alignItems="center" item xs={12} sm={6} sx={{ height: '40px'}}>
                {!isMobile && isTimerRunning && !check && <Button type='submit' variant="contained" size="large">Probar</Button>}
                {(!isTimerRunning || check) && index != 5 && <Fade in={true}><Button type='submit' variant="contained" size="large" onClick={siguiente} color="success">Continuar</Button></Fade>}
                {(!isTimerRunning || check) && index == 5 && <Button type='submit' variant="contained" size="large" onClick={finalizar}>Finalizar</Button>}              
            </Grid>

            <Grid item xs={2} container justifyContent="center" alignItems="center" >
              <ScoreIcon scoreProp={puntos}></ScoreIcon>
            </Grid>

            <Grid container justifyContent="center" alignItems="center" item xs={8}>
              <Typography component="div" variant="body1">{index}/5</Typography>
            </Grid>
            <Grid item xs={2} container justifyContent="center" alignItems="center" >
              <Timer isRunning={isTimerRunning} handleToggleTimer={setIsTimerRunning} tiempo={setTiempo} />
            </Grid>
          </Grid>

        </Paper>
        </form>

        {isMobile && <Teclado onKeyPress={onKeyPress}></Teclado>}

        
      </>
  )
};

export default Pregunta;