import { useFetch } from './useFetch'
import './App.css'
import React, { useState } from 'react'
import { useEffect } from 'react'
import Pregunta from './components/Pregunta'
import CircularProgress from '@mui/material/CircularProgress'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import { Typography, Grid, Button, Alert, Box, Container, Paper, useTheme } from '@mui/material'
import ScoreIcon from './components/ScoreIcon'
import Proximamente from './components/Proximamente'
import Introduccion from './components/Introduccion'
import ShareComponent from './components/ShareComponent'
import RetomarPreguntas from './components/RetomarPreguntas'

function App() {
  const getUrl = "https://juego-backend.educoteca.es/";
  const shareUrl = `${window.location.origin}/share/`;
  const date = new Date();
  const fechaHoy = `${("0" + date.getDate()).slice(-2)}/${("0" + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`;
  const maxIntentos = 5;

  const { data, loading, error } = useFetch(getUrl);
  const respuestasHoy = JSON.parse(localStorage.getItem(fechaHoy)) || [];  
  const [numPregunta, setNumPregunta] = useState(respuestasHoy.length);
  const [finalizar, setFinalizar] = useState(false);  
  const [listaResumen, setListaResumen] = useState(respuestasHoy);
  const [expanded, setExpanded] = React.useState(false);  
  const [totalPuntos, setTotalPuntos] = useState(parseInt(localStorage.getItem("puntosTotal")) || 0);
  const [hoyPuntos, setHoyPuntos] = useState(0);  
  const [puedeJugar, setPuedeJugar] = useState(localStorage.getItem(fechaHoy) === null || (localStorage.getItem(fechaHoy) !== null && numPregunta < 5));  
  const [abrirExplicaciones, setAbrirExplicaciones] = useState(respuestasHoy.length == 0);
  const [isRetomarOpen, setIsRetomarOpen] = useState(respuestasHoy.length > 0); 
  const theme = useTheme();

  useEffect(() => {
    setHoyPuntos(calcularSumaPuntos());
    setTotalPuntos(localStorage.getItem("puntosTotal"));
  }, []);

  const calcularSumaPuntos = () => {
    const elementos = JSON.parse(localStorage.getItem(fechaHoy)) || [];
    return elementos.reduce((acumulador, elemento) => acumulador + elemento.puntos, 0);
  };

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    };

  const handleSiguiente = () => {
    setNumPregunta(numPregunta + 1)
  }

  const handleFinalizar = () => {
    setFinalizar(true);
    const puntosCalculados = calcularSumaPuntos();
    setHoyPuntos(puntosCalculados);
    const puntosTotalesActuales = parseInt(localStorage.getItem("puntosTotal"), 10) || 0;
    const nuevosPuntosTotales = puntosTotalesActuales + puntosCalculados;
    setTotalPuntos(nuevosPuntosTotales);
    localStorage.setItem("puntosTotal", nuevosPuntosTotales.toString());
}

  const handleResumen = (nuevoElemento) => {
    setListaResumen(prevListaResumen => {
      const updatedResumen = [...prevListaResumen, nuevoElemento];
      localStorage.setItem(fechaHoy, JSON.stringify(updatedResumen));
      return updatedResumen;
    });
  }

  const getListaResumenHoy = () => {
    return JSON.parse(localStorage.getItem(fechaHoy));

  }

  const getURLShare = () => {
    const shareList = JSON.parse(localStorage.getItem(fechaHoy));
    const listaShare = [fechaHoy];
    
    shareList.map((contenido, index) => {
      const { ask, puntos, tiempo, acertado } = contenido;
      if(acertado){
        const c = ask.codigo;
        const t = tiempo;
        const p = puntos;
        listaShare.push(c + "|"+  p + "|" + t);
      }      
    });
    const encodedListaShare = btoa(JSON.stringify(listaShare));
    return shareUrl + encodedListaShare;
  };

  const getPregunta = (ask, index) => {
    let esVisible = numPregunta === index
    if (esVisible) {
      return <Pregunta key={ask.codigo}
        ask={ask}
        isActive={esVisible}
        siguiente={handleSiguiente}
        maxIntentos={maxIntentos}
        index={index + 1}
        finalizar={handleFinalizar}
        handleResumen={handleResumen}></Pregunta>;
    }
  }

  return (<>
    <Box
      className="App"
      sx={{
        display: 'flex',
        width: '100%',
        bgcolor: 'background.default',
        color: 'text.primary',
      }}
    >
      {loading && <Box sx={{ display: 'flex' }}> <CircularProgress /> </Box>}

      {(puedeJugar) && <Introduccion abrirExplicaciones={abrirExplicaciones} setAbrirExplicaciones={setAbrirExplicaciones}></Introduccion>}

      {(puedeJugar) && <RetomarPreguntas isOpen={isRetomarOpen} onClose={() => {setIsRetomarOpen(false) }}></RetomarPreguntas>}

      {!isRetomarOpen && !abrirExplicaciones && puedeJugar && !finalizar && data?.map((ask, index) => (
        getPregunta(ask, index)
      ))}

      {(finalizar || !puedeJugar) && <> <Container>

        <div className="accordion">
          {getListaResumenHoy()?.map((contenido, index) => (
            <Accordion expanded={expanded === 'panel' + index} onChange={handleChange('panel' + index)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{
                borderRadius: 2, backgroundColor: contenido.acertado ? 'rgb(237, 247, 237)' : 'rgb(253, 237, 237)'
              }}>
                <Alert sx={{ width: '100%' }} severity={(contenido.acertado ? "success" : "error")}><Typography component="div" >  {index + 1}. Relación entre <b>{contenido.ask.palabra1}</b> y <b>{contenido.ask.palabra2}</b> {(contenido.acertado ? "Puntos: " + contenido.puntos + " acertó en " + (60 - contenido.tiempo) + " seg " : "")} </Typography></Alert>
              </AccordionSummary>
              <AccordionDetails>
                {contenido.ask.relacion}
                <Typography align='left' sx={{ color: 'text.secondary' }} component="div" variant='body1'> {contenido.ask.explicacion}  </Typography>
              </AccordionDetails>
            </Accordion>
          ))}

        </div>
        <Paper>
          <Box sx={{
            m: 2,
            p: 2
          }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <Typography variant="body1">Hoy has ganado:</Typography>
              </Grid>
              <Grid item xs={6} container justifyContent="flex-start" alignItems="center">
                <ScoreIcon scoreProp={hoyPuntos} />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">Tienes un total de:</Typography>
              </Grid>
              <Grid item xs={6} container justifyContent="flex-start" alignItems="center">
                <ScoreIcon scoreProp={totalPuntos} />
              </Grid>
            </Grid>
          </Box>
          <Proximamente></Proximamente>
          <ShareComponent url={getURLShare()}></ShareComponent>


          <Button type='submit' variant="contained" size="medium" onClick={() => { window.localStorage.clear() }}>Reiniciar puntos</Button>
        </Paper></Container>

      </>}

    </Box>
  </>
  );
}

export default App;